.root {
  margin-bottom: 75px;
}

.asset {
  display: flex;
  margin-bottom: 30px;
}

.image {
  margin-right: 20px;
  max-width: 415px;
  width: 100px;
}

.title {
  font-size: 20px;
}

.edit-input input {
  min-width: 30vw;
}

.no-stamps {
  color: #777;
  font-size: 12px;
  margin-left: 35px;
}
.root {
  margin: auto !important;
}

.root > div {
}

.image {
  max-width: 415px;
}

.title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.aka > div > div {
  font-weight: bold;
}

.properties {
  margin-top: 40px;
}

.terpenes {
  font-weight: bold;
}

@media (min-width: 768px) {
  .root {
    display: flex;
  }
}

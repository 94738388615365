.root {
  margin-bottom: 20px !important;
  max-width: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .root {
    max-width: 415px;
  }
}

.layout {
  display: flex;
}

.account {
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.content {
  padding: 0 50px;
}